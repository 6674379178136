<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

<!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input v-model="searchForm.stu_name" size="small" placeholder="姓名"
          style="max-width: 200px; margin-right: 10px;" />
        <el-input v-model="searchForm.phone" size="small" placeholder="手机号"
          style="max-width: 200px; margin-right: 10px;" />
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>



    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="stu_name" label="姓名" width="120" align="center"></el-table-column>
      <el-table-column prop="phone" label="联系电话" width="150" align="center"></el-table-column>
      <el-table-column prop="content" label="留言内容" align="center" min-width="135" show-overflow-tooltip/>
      <el-table-column prop="cdate" label="留言时间" width="180" align="center"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red"
            @confirm="onDel(scope.row)" placement="left" title="是否确定删除？">
            <el-tag class="ml10 pointer" slot="reference" type="danger" size="small">删除</el-tag>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      importDialog: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {}
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    onView(row) {
      this.visible = true
      this.detailForm = row
    },
    getYears() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/njwx_stu_years").then(res => {
          this.YearList = res.data
          this.searchForm.year = res.data[0].gyear
          resolve()
        }).catch(err => {
          resolve()

        })
      })
    },
    getlist() {
      this.$http
        .post("/api/by_stu_message_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_name: this.searchForm.stu_name,
          phone: this.searchForm.phone,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    onDel(row) {
      this.$http.post("/api/by_stu_message_delete", { id: row.id }).then((res) => {
        this.$message.success('删除成功！')
        this.getlist();
      });
    },
  },
};
</script>
